

































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ContractDialogue from "@/vue/components/dialogues/ContractDialogue.vue";
import OrderDialogue from "@/vue/components/dialogues/OrderDialogue.vue";
import utils from "@/utilities/Utils";
import { ContractSearchParameters } from "@/model/Api/SearchParams/ContractSearchParameters";
import { ContractVM, IContractVM } from "@/model/Api/VM/ContractVM";
import { LookupItem, ILookupItem } from "@/model/LookupItem";
import { ContractLookup, IContractLookup } from "@/model/ContractLookup";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        ContractDialogue,
        OrderDialogue
    }
})
export default class Contracts extends Vue {

    async mounted(): Promise<void> { 
        this.loadSuppliers();
        this.loadBrands();
    }

    //
    // -- properties
    //

    private contractHeaders = [
        { text: "PO Number", value: "contract.poNumber", sortable: false },
        { text: "Supplier", value: "supplierDescription", sortable: false },
        { text: "Description", value: "contract.description", sortable: false },
        { text: "Created", value: "created", sortable: false }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new ContractSearchParameters();
    private suppliers: Array<LookupItem> = [];
    private brands: Array<ContractLookup> = [];
    private productGroups: Array<ContractLookup> = [];
    private contractList: Array<ContractVM> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.supplierID")
    private onSupplierIDChanged() {
        if (this.searchParameters.supplierID == undefined) {
            this.searchParameters.supplierID = 0;
        }
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.brandID")
    private onBrandIDChanged() {
        if (this.searchParameters.brandID == undefined) {
            this.searchParameters.brandID = 0;
            this.productGroups = [];
            this.searchParameters.productGroupID = 0;
        } else if (this.searchParameters.brandID == 0) {
            this.productGroups = [];
            this.searchParameters.productGroupID = 0;
        } else {
            this.loadProductGroups();
        }

        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.productGroupID")
    private onProductGroupIDChanged() {
        if (this.searchParameters.productGroupID == undefined) {
            this.searchParameters.productGroupID = 0;
        } else if (this.searchParameters.productGroupID == 0) {
            return;
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    async loadSuppliers(): Promise<void> {
        this.searchParameters.supplierID = 0;
        this.suppliers = [];
        const response = await apiClient.get("/api/supplier/userSuppliers");
        this.suppliers.push(...response.map((l: ILookupItem) => new LookupItem(l)));
    }

    async loadBrands(): Promise<void> {
        this.searchParameters.brandID = 0;
        this.brands = [];
        const response = await apiClient.get("/api/contract/contractBrands");
        this.brands.push(...response.map((l: IContractLookup) => new ContractLookup(l)));
    }

    async loadProductGroups(): Promise<void> {
        this.searchParameters.productGroupID = 0;
        this.productGroups = [];
        const response = await apiClient.get(`/api/contract/productGroups?brandID=${this.searchParameters.brandID}`);
        this.productGroups.push(...response.map((l: IContractLookup) => new ContractLookup(l)));
    }

    open(contract: IContractVM): void {
        const dialog: ContractDialogue = this.$refs.contractDialogue as ContractDialogue;
        dialog.open(contract.contract.id, contract.supplierDescription, contract.contract.poNumber);
    }

    createOrder(conID: string): void {
        const dialog: OrderDialogue = this.$refs.orderDialogue as OrderDialogue;
        dialog.open(conID);
    }

    refreshSearch(): void {
        this.contractList = [];
        this.searchParameters.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    reset(): void {
        this.searchParameters.searchParameters.searchText = "";
        this.searchParameters.supplierID = 0;
        this.searchParameters.brandID = 0;
        this.debouncedRefreshSearch();
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/contract/search", this.searchParameters);
        if (response.list.length) {
            this.contractList.push(...response.list.map((c: IContractVM) => new ContractVM(c)));
            this.searchParameters.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
