import { IContract, Contract } from "../../Contract";
import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IContractVM {
    contract: IContract;
    supplierDescription: string;
    created: Date;
}

export class ContractVM implements IContractVM {

    constructor(data?: IContractVM) {
        if (data) this.update(data);
    }

    update(data: IContractVM): void {
        mapData(data, { 
            root: () => this,
            contract: () => new Contract()
        });
    }

    contract: Contract = new Contract();
    supplierDescription: string = "";
    created: Date = new Date(utils.emptyDateValue);
}
