






























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import store from "@/store/store";
import { ContractLine, IContractLine } from "@/model/ContractLine";
import { UserRole } from "@/model/Enums";

@Component({ components: { ApiButton } })

export default class ContractDialogue extends Vue {

    //
    // -- properties
    //

    private contractHeaders = [
        { text: "Description", value: "description", sortable: true },
        { text: "Colour", value: "colour", sortable: true },
        { text: "Size", value: "size", sortable: true },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Barcode", value: "barcode", sortable: false },
        { text: "SKU", value: "sku", sortable: true }
    ];

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private lines: Array<ContractLine> = [];

    //
    // -- computed properties
    //

    private get isRetailer() {
        return store.state.signedInUser?.role == UserRole.Retailer
    }

    //
    // -- methods
    //

    async open(id: string, supplier: string, poNumber: string): Promise<void> {
        this.reset();
        this.dialogueTitle = supplier + ' - ' + poNumber;

        const response = await apiClient.get(`/api/contract/loadLines?contractID=${id}`);
        this.lines.push(...response.map((l: IContractLine) => new ContractLine(l)));

        this.showDialogue = true;
    }

    private reset() {
        this.lines = [];
    }

    private async contractExtract() {
        const blob: Blob = await apiClient.get(`/api/contract/ContractExtract?id=${this.lines[0].contractID}`);
        utils.downloadBlob(document, blob, `ContractExtract.csv`);
    }

    private async createOrder() {
        this.showDialogue = false;
        this.$emit("createOrder", this.lines[0].contractID);  
    }
}
