import { mapData } from "@/utilities/DataMapping";

export interface IContractLookup {
    id: number;
    groupID: number;
    description: string;
    parentLookupID: number;
}

export class ContractLookup implements IContractLookup {

    constructor(data?: IContractLookup) {
        if (data) this.update(data);
    }

    update(data: IContractLookup): void {
        mapData(data, { 
            root: () => this
         });
    }

    id: number = 0;
    groupID: number = 0;
    description: string = "";
    parentLookupID: number = 0;
}
